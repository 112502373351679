function loadOktaSignInWidget(domain, clientId, redirectUri, issuer, forgotPassword, widgetElementId, callback, authApiUrl, googleIdp, isGoogleEnabled) {
    var config = {
        baseUrl: 'https://' + domain,
        clientId: clientId,
        redirectUri: redirectUri,
        authParams: {
            issuer: issuer
        },
        i18n: {
            'en': {
                'primaryauth.username.placeholder': 'Email Address',
                'errors.E0000004': 'The email and/or password you\'ve entered doesn\'t match our records. Please try again.',
                'errors.E0000119': 'Your account has been locked due to multiple failed login attempts. Please reset your password to regain access or try again at a later time.',
                'error.auth.lockedOut': 'Your account has been locked due to multiple failed login attempts. Please reset your password to regain access or try again at a later time.'
            }
        },
        scopes: ["openid", "profile", "offline_access", "psc-authmode-userpass"],
        helpLinks: {
            // Trim login prefix, IE does not include leading slash
            forgotPassword: forgotPassword + '?redirectUrl=' + window.location.pathname.replace('/login/', '').replace('login/', '')
        },
        features: {
            rememberMe: false,
            showPasswordToggleOnSignInPage: true
        }
    };
    if (isGoogleEnabled) {
        config.idps = [
            { type: 'GOOGLE', id: googleIdp }
        ];
        config.idpDisplay = "SECONDARY";
    };
    const oktaSignIn = new OktaSignIn(config);
    oktaSignIn.remove();
    oktaSignIn.showSignInToGetTokens({
        el: '#' + widgetElementId
    }).then(function (tokens) {
        oktaSignIn.authClient.tokenManager.setTokens(tokens);
        callback(tokens)
    }).catch(function (err) {
        console.error(err);
    });
}

// onWidgetRendered will execute 'callback' when the okta signin widget has completed rendering
function onWidgetRendered(widgetElementId, callback) {
    const observerCallback = () => {
        const el = $("#okta-signin-username");
        if (el) {
            observer.disconnect();
            callback();
        }
    };
    const observer = new MutationObserver(observerCallback);
    let observerNode = $('#' + widgetElementId)[0];
    if (!observerNode)
        console.err("widgetElementId is invalid; The widget renderer callback will not be executed.")
    observer.observe(observerNode, { subtree: true, childList: true });
}


function callback(tokens) {
    document.getElementById("AccessToken").value = tokens.accessToken.value;
    document.getElementById("RefreshToken").value = tokens.refreshToken.value;
    document.getElementById("ClientId").value = tokens.idToken.clientId;
    document.getElementById("okta-access-token-form").submit();
}

function loadOkta({ domain, clientId, redirectUri, issuer, forgotPassword, widgetElementId, authApiUrl, googleIdp, isGoogleEnabled }) {
    loadOktaSignInWidget(domain, clientId, redirectUri, issuer, forgotPassword, widgetElementId, callback, authApiUrl, googleIdp, isGoogleEnabled);
    onWidgetRendered(
        widgetElementId,
        function () {
            let oktaUsernameField = document.getElementById("okta-signin-username");
            if (oktaUsernameField) {
                oktaUsernameField.addEventListener('focusout', function (event) {
                    perkspot.login.validateEmail(event.target.value)
                })
            }
        })
};